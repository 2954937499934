const GOV_ID_BIOMETRIC: string = 'gov_id_idv';
const GOV_ID_ONLY: string = 'gov_id_only';
const BIOMETRIC_ONLY: string = 'biometric_only';

const DRIVERS_LICENSE = 'drivers_license';
const MEDICARE = 'medicare';
const PASSPORT = 'passport';
const PROOF_OF_AGE = 'proof_of_age';

export { BIOMETRIC_ONLY, DRIVERS_LICENSE, GOV_ID_BIOMETRIC, GOV_ID_ONLY, MEDICARE, PASSPORT, PROOF_OF_AGE };
