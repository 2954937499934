import { FrankieOneVerificationStatus } from '@/core/enums/FrankieOneVerificationStatus';
import { useAuthStore } from '@/modules/auth/store/auth';
import type { ProfileType } from '@/types/FrankieOne';
import { BIOMETRIC_ONLY, GOV_ID_BIOMETRIC, GOV_ID_ONLY } from '@/utils/enums/FrankieOne';
import { storeToRefs } from 'pinia';

export default function useFrankieOne() {
  const route = useRoute();
  const router = useRouter();
  const config = useRuntimeConfig();

  const authStore = useAuthStore();
  const { user, isClient } = storeToRefs(authStore);
  const { getDocument } = authStore;

  const fetching = ref(false);
  const loading = ref(true);
  const verifying = ref(false);

  const oneSdk = ref();
  const oneSdkIndividual = ref();
  const frankieOneStatus = ref<string | null>(null);
  const frankieOneSession = ref();

  const { showSnackbar } = useSnackbar();

  const isVerificationPassed = computed((): boolean => {
    return frankieOneStatus.value === FrankieOneVerificationStatus.PASS;
  });
  const isVerificationFailed = computed((): boolean => {
    return frankieOneStatus.value === FrankieOneVerificationStatus.FAIL;
  });
  const isVerificationRefer = computed((): boolean => {
    return frankieOneStatus.value === FrankieOneVerificationStatus.REFER;
  });
  const isVerificationUnchecked = computed((): boolean => {
    return frankieOneStatus.value === FrankieOneVerificationStatus.UNCHECKED;
  });
  const isVerificationNone = computed((): boolean => {
    return frankieOneStatus.value === FrankieOneVerificationStatus.NONE;
  });

  const getFrankieOneStatus = async (): Promise<void> => {
    if (fetching.value) return;

    fetching.value = true;
    frankieOneStatus.value = null;

    const { data, error } = await useFetchData('frankieone/entity-check');

    if (data.value?.data) {
      frankieOneStatus.value = data.value.data.action_recommended;
    } else if (error.value) {
      showSnackbar({
        text: error.value,
        state: false,
      });
    }

    fetching.value = false;
  };

  const getFrankieOneSession = async (): Promise<void> => {
    if (fetching.value) return;

    fetching.value = true;
    frankieOneSession.value = null;

    const { data, error, execute, pending } = useSubmit();
    await execute('frankieone/machine-session', {
      method: 'POST',
    });

    if (data.value?.data) {
      frankieOneSession.value = data.value.data.session_object;
    } else if (error.value) {
      back();
    }

    fetching.value = false;
  };

  const initializeFrankieOne = async (
    elementId: string,
    profileType: ProfileType = GOV_ID_BIOMETRIC
  ): Promise<void> => {
    if (frankieOneSession.value) {
      // @NOTE: Importing package above results in some ES issues
      const FrankieOne = await import('@frankieone/one-sdk');
      oneSdkIndividual.value = null;

      oneSdk.value = await FrankieOne.OneSDK({
        session: frankieOneSession.value,
        mode: {
          modeName: import.meta.dev ? 'development' : 'production',
        },

        recipe: {
          form: {
            provider: {
              name: 'react',
              googleApiKey: config.public.googleApiKey,
            },
          },
        },
      });

      // oneSdk.value.on('*', console.log);
      oneSdkIndividual.value = oneSdk.value.individual();
      // @NOTE: Verify the users depending on profile type,
      // not in use anymore as BE is setting this when we call the session token
      // oneSdkIndividual.value.setProfileType(profileType);

      // @NOTE: Render IDV or KYC base on user's selected documen
      if ([GOV_ID_BIOMETRIC, BIOMETRIC_ONLY].includes(profileType)) {
        renderIDV(elementId);
      } else if (profileType === GOV_ID_ONLY) {
        renderKYC(elementId);
      } else {
        fail('FrankieOne: Invalid profile type');
      }
    } else {
      fail('FrankieOne: Failure to initialize due to missing session token');
    }
  };

  const renderKYC = async (elementId: string): void => {
    const welcome = oneSdk.value.component('form', {
      name: 'WELCOME',
      type: 'manual',
      /*descriptions: [
              { label: 'This is a sample dynamic page.', style: {} },
              { label: 'It can contain multiple paragraphs.', style: {} },
            ], */
      //cta: {style: {'ff-button':{backgroundColor: "red"}}}
    });
    const consent = oneSdk.value.component('form', { name: 'CONSENT' });

    const personal = oneSdk.value.component('form', {
      name: 'PERSONAL',
      type: 'manual',
    });

    const docs = oneSdk.value.component('form', {
      name: 'DOCUMENT',
      type: 'manual',
      numberOfIDs: 1,
      documents: [
        // {
        //   type: "DRIVERS_LICENCE"
        // },
        {
          type: 'NATIONAL_HEALTH_ID',
        },
        // {
        //   type: "PASSPORT"
        // }
      ],
    });

    const review = oneSdk.value.component('form', {
      name: 'REVIEW',
      type: 'manual',
      verify: true,
    });

    const retry = oneSdk.value.component('form', {
      name: 'RETRY',
      type: 'manual',
    });

    const result_fail = oneSdk.value.component('form', {
      name: 'RESULT',
      type: 'manual',
      state: 'FAIL',
      title: { label: 'Max attempt reached' },
      descriptions: [
        { label: 'You have reached all the attempts. Our officer will review your details and get in touch.' },
      ],
      cta: { label: 'Close' },
    });

    welcome.mount(`#${elementId}`);
    welcome.on('form:welcome:loaded', () => {
      hideLoading();
    });
    welcome.on('form:welcome:ready', () => {
      hideLoading();
      consent.mount(`#${elementId}`);
    });

    welcome.on('form:welcome:failed', () => {
      fail();
    });

    welcome.on('*', (message) => {
      console.log(message);
    });

    consent.on('form:consent:ready', async () => {
      docs.mount(`#${elementId}`);
    });

    docs.on('form:document:back', async ({ inputInfo }) => {
      consent.mount(`#${elementId}`);
    });

    docs.on('form:document:ready', async ({ inputInfo }) => {
      review.mount(`#${elementId}`);
    });

    review.on('form:result:success', async ({ inputInfo }) => {
      await getFrankieOneStatus();
      await approveIdDocumentVerification();

      showSnackbar({
        text: 'Verification successful!',
      });

      back(true);
    });

    review.on('form:result:failed', async ({ inputInfo }) => {
      back(false);
    });

    review.on('form:result:pending', async ({ inputInfo }) => {
      await getFrankieOneStatus();

      showSnackbar({
        text: 'Verification pending!',
      });

      back(true);
    });

    let count = 0;
    review.on('form:result:partial', async () => {
      if (count < 2) {
        docs.mount(`#${elementId}`);
        count += 1;
      } else {
        result_fail.mount(`#${elementId}`);
      }
    });
  };

  const renderIDV = async (elementId: string): void => {
    const idvFlow = oneSdk.value.flow('idv');
    const consent = oneSdk.value.component('form', { name: 'CONSENT' });

    // The module "IDV flow" exposes a dictionary of idv statuses using
    // friendlier names for better code clarity
    const idvStatus = idvFlow.statuses;

    // Show the idv flow
    const startIdv = (): void => {
      showLoading();
      idvFlow.mount(`#${elementId}`);
    };
    // Show the consent form
    const startConsent = (): void => {
      showLoading();
      setVerifying(false);
      consent.mount(`#${elementId}`);
    };

    startConsent();

    // Register consent events
    consent.on('form:consent:loaded', async () => {
      hideLoading();
    });
    consent.on('form:consent:ready', async () => {
      oneSdkIndividual.value.addConsent('idv');
      startIdv();
    });

    // Register IDV events
    idvFlow.on('ready', () => {
      hideLoading();
      setVerifying(true);
    });
    idvFlow.on('detection_complete', () => {
      unmount(elementId);
    });
    idvFlow.on('results', async ({ checkStatus, document, entityId }) => {
      hideLoading();

      if (checkStatus === idvStatus.COMPLETE) {
        // Once the extraction is COMPLETE, optionally retrieve the overall check result
        // using the module "individual"
        verify();
      }

      if (checkStatus === idvStatus.FAILED) {
        fail();
      }
    });
    idvFlow.on('input_required', ({ entityId }, checkStatus) => {
      hideLoading();
      startIdv();
    });
    idvFlow.on('error', ({ message, payload: { errorStatus } }) => {
      const isMissingConsent = errorStatus === idvStatus.AWAITING_CONSENT;
      // In case the error is related to the consent not being captured
      // Attempt to capture the consent and provide it to the OneSDK
      // Restart the flow by calling the method "mount" again
      if (isMissingConsent) {
        startConsent();
      } else {
        hideLoading();
        fail(message);
      }
    });
  };

  const unmount = (elementId: string): void => {
    document.getElementById(`${elementId}`).outerHTML = '';
  };

  const verify = async (): Promise<void> => {
    try {
      const checkSummary = await oneSdkIndividual.value.submit({ verify: true });
      const checkStatus = checkSummary?.status?.key;

      const isPassed = checkStatus === 'PASSED';
      if (isPassed || checkStatus === 'REFER') {
        await getFrankieOneStatus();

        if (isPassed) {
          await approveIdDocumentVerification();
        }

        showSnackbar({
          text: `Verification ${isPassed ? 'complete' : 'pending'}!`,
        });

        back(true);
      }

      if (checkSummary.status.key === 'FAIL') {
        fail();
      }
    } catch (e) {
      fail('Something went wrong, cannot verify details');
    }
  };

  const fail = (message: string = 'Verification failed!'): void => {
    showSnackbar({
      text: message,
      state: false,
    });

    back(false);
  };

  const back = (success: boolean = true): void => {
    const redirect = {
      name: '',
      params: {},
      query: {
        from: 'verification',
      },
    };

    if (route.query?.from === 'profile') {
      if (isClient.value) {
        redirect.name = 'profile-profile-information-identity-documents';
      } else {
        redirect.name = 'profile-profile-information-required-documents-document';
        redirect.params.document = 'id-verification';
      }
    } else {
      redirect.name = 'onboarding';
      redirect.query.form = success
        ? isClient.value
          ? 'support-requirements'
          : 'required-documents'
        : 'id-verification';
    }

    router.push(redirect);
  };

  const showLoading = (): void => {
    return setLoading(true);
  };

  const hideLoading = (): void => {
    return setLoading(false);
  };

  const setLoading = (bool: boolean): void => {
    loading.value = bool;
  };

  const setVerifying = (bool: boolean): void => {
    verifying.value = bool;
  };

  const approveIdDocumentVerification = async (): void => {
    // @NOTE: Refresh `/me` to get latest documents data obj.
    await reloadNuxtApp();
  };

  return {
    fetching,
    loading,
    verifying,

    oneSdk,
    frankieOneStatus,
    frankieOneSession,

    isVerificationPassed,
    isVerificationFailed,
    isVerificationRefer,
    isVerificationUnchecked,
    isVerificationNone,

    getFrankieOneStatus,
    getFrankieOneSession,
    initializeFrankieOne,
  };
}
